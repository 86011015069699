<template>
    <div
      style="
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0px;
        z-index: 2000;
        right: 0px;
        opacity: 1;
      "
    >
      <img
        style="width: 100%; height: 100%"
        src="../assets/login_cover.jpg"
        alt=""
      />
    </div>
    <div class="login justify-content-center align-items-center">
    <div class="text-center">
      <br /><br />
      <form class="form-signin border">
        <center>
          <div class="circle-image2">
            <img
              class="logoimage"
              src="../assets/logosite.png"
              width="60"
              height="60"
              alt=""
              srcset=""
            />
          </div>
        </center>
        <h1 class="h3 mb-3 font-weight-normal">Connectez-vous</h1>

        <div v-if="user_error" class="alert alert-danger" role="alert">
          Paramètre de connexion incorrect !
        </div>
        <label for="inputEmail" class="sr-only">Identifiant</label>
        <input
          v-model="identifiant"
          type="text"
          id="inputEmail"
          class="form-control"
          placeholder="Identifiant"
          required
          autofocus
        />
        <label for="inputPassword" class="sr-only">Mot de passe</label>
        <input
          v-model="mdp"
          type="password"
          id="inputPassword"
          class="form-control"
          placeholder="Password"
          required
        />

        <button
          
          class="btn btn-lg btn-primary btn-block"
          type="submit"
        >
          Connection
        </button>
        <p class="mt-5 mb-3 text-muted"></p>
      </form>
      <div class="mt-3">
        <router-link class="btn p-2" to="/connexion">Login</router-link>
      </div>
    </div>
  </div>
</template>

